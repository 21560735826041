import React, {useState} from "react";
import "../screens/link_gen.css"
import firebase from 'firebase/app'
import 'firebase/database'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowLeftCircleIcon } from "lucide-react";
import {
  faSquareMinus
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import ImportManager from "./ImportManager";
import GivePro from "./pro";

library.add(faSquareMinus);

function LinkGenerator() {
  // State for the number of links, links, authentication, and password
  const [noofLinks, setNoofLinks] = useState(1);
  const [links, setLinks] = useState({
    custom: [],
    custom_company: [],
    card: [],
    keychain: [],
    button: [],
    display: [],
  });

  const [authenticated, setAuthenticated] = useState(false);
  const [company, setCompany] = useState("");
  const [selectedType, setSelectedType] = useState('card'); // For select dropdown



  const [password, setPassword] = useState("");
  const correctPassword = "Dassoft123!"; // Set your password here

  const deleteLink = (link_delete) => {
    const updatedData = {};

    Object.keys(links).forEach(category => {
        updatedData[category] = links[category].filter(link => link !== link_delete);

    });

    setLinks(updatedData)

  }

  const updateData = (linkType, link) => {
    for (let key in links) {
        links[key].forEach(url => {
            const token = url.split('/').pop();
            firebase
            .database()
            .ref(`users/card_details/${token}`)
            .update({ link: key === "custom_company" ? `https://popitnl.nl/pages/${selectedType.toLowerCase()}-activeren/` : `https://popitnl.nl/pages/${key}-activeren/` })
            .then(() => {
                // console.log(`Data updated for ${linkType}`);
            })
            .catch((error) => {
                console.error('Error updating data:', error);
            });
        });
    }

  };

  const generateCSVContent = () => {
    const csvContent = [];
    csvContent.push(['Custom Card Links','Custom Company Links', 'Card Links', 'Keychain Links', 'Button Links', 'Display Links']);

    let greatestLength = 0;

    // Check the length of each array and update greatestLength if needed
    if (links.card.length > greatestLength) {
      greatestLength = links.card.length;
    }
    if (links.custom_company.length > greatestLength) {
      greatestLength = links.custom_company.length;
    }
    if (links.button.length > greatestLength) {
      greatestLength = links.button.length;
    }
    if (links.keychain.length > greatestLength) {
      greatestLength = links.keychain.length;
    }
    if (links.custom.length > greatestLength) {
      greatestLength = links.custom.length;
    }
    if (links.display.length > greatestLength) {
      greatestLength = links.display.length;
    }


    for (let i = 0; i < greatestLength; i++) {
      const customLink = links.custom[i] || '';
      const cardLink = links.card[i] || '';
      const keychainLink = links.keychain[i] || '';
      const buttonLink = links.button[i] || '';
      const displayLink = links.display[i] || '';
      const custom_companyLink = links.custom_company[i] || '';

      csvContent.push([customLink, custom_companyLink, cardLink, keychainLink, buttonLink, displayLink]);
    }

    return csvContent.map((row) => row.join(',')).join('\n');
  };

  // Function to trigger CSV download
  const downloadCSV = () => {
    updateData()
    //updateData(tok, link)
    const csvContent = generateCSVContent();
    const blob = new Blob([csvContent], { type: 'text/csv' });

    const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'links.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

  //   const formData = new FormData();
  //   formData.append('file', blob, 'links.csv');
  //   fetch('http://16.171.162.205:6000/send-email', {
  //     method: 'POST',
  //     body: formData,
  //   })
  //   .then(response => {
  //   if (response.ok) {
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.style.display = 'none';
  //     a.href = url;
  //     a.download = 'links.csv';
  //     document.body.appendChild(a);
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //     document.body.removeChild(a);
  //   }
  //   else{
  //     console.log('Failed to send email')
  //   }
  // }).catch(e => console.log(e)) 
  };

  // Function to handle login
  const handleLogin = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setAuthenticated(true);
    } else {
      alert("Incorrect password!");
    }
  };
  const [active, setactive] = useState(null);


    const generatedTokens = new Set();

    function generateToken(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token;
    do {
        token = '';
        for (let i = 0; i < length; i++) {
        token += characters.charAt(Math.floor(Math.random() * characters.length));
        }
    } while (generatedTokens.has(token));

    generatedTokens.add(token);
    return token;
    }

    const generateLink = (linkType) => {
        const newLinks = [];
    
        var link = ''
    
        for (let i = 1; i <= noofLinks; i++) {
            const token = generateToken(10);
            const newLink = linkType === "custom_company" ? `https://popitapp.nl/${company}/activate/${token}`: `https://popitapp.nl/activate/${token}`;

            // if(linkType === "custom_company"){
            //   console.log(selectedType)
            //   link = `https://popitnl.nl/pages/${selectedType.toLowerCase()}-activeren/`;
            // }
            // else{
            //   link = `https://popitnl.nl/pages/${linkType}-activeren/`;
            // }

            newLinks.push(newLink);
            
            //updateData(token, link);
        }

        setLinks((prevLinks) => ({
          ...prevLinks,
          [linkType]: newLinks,
        }));
    
      };

  return (

    <div className="background h-[100vh] w-full flex flex-col justify-center items-center">
      {!authenticated && (
        <div
          style={{ backgroundColor: "rgba(255, 255, 255, 0.205)" }}
          className="backdrop-blur-3xl backdrop_image border-2  p-10 flex flex-col gap-10 rounded-lg w-[90%] max-w-[400px]"
        >
          <h1 className="font-semibold  text-3xl text-center  w-fit mx-auto text-black  rounded-md">
            Login
          </h1>
          <form onSubmit={handleLogin} className="flex flex-col gap-5">
            <label htmlFor="password" className="text-2xl">
              Password:
            </label>
            <input
              type="password"
              id="password"
              className="p-2 border-2  border-blue-400 outline-blue-400"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-lg"
            >
              Login
            </button>
          </form>
        </div>
      )}
      {authenticated && active === null && (
        <div className="topbar lg:w-[50%]  backdrop_image  backdrop-blur-2xl h-fit mt-5 bg-gray-50 p-10   flex  flex-col justify-around gap-5 rounded-sm lg:rounded-2xl ">
          <img src={require("../images/Logo_white_WB.png")} style={{width: "70%", height: 150, objectFit: 'cover', alignSelf: "center"}}/>
          <button
            onClick={() => {
              setactive(0);
            }}
            className=" hover:bg-blue-500 active:bg-blue-700 text-xl font-semibold bg-blue-600 text-white  p-5 flex-1 rounded-xl"
          >
            Links Generator
          </button>
          <button
            onClick={() => {
              setactive(1);
            }}
            className=" hover:bg-blue-500 active:bg-blue-700 text-xl font-semibold bg-blue-600 text-white  p-5 flex-1 rounded-xl"
          >
            Import Manager
          </button>
          <button
            onClick={() => {
              setactive(2);
            }}
            className=" hover:bg-blue-500 active:bg-blue-700 text-xl font-semibold bg-blue-600 text-white  p-5 flex-1 rounded-xl"
          >
            Manager
          </button>

        </div>
      )}
      {active === 0 && authenticated ? (
        <div
        // style={{
        //   backdropFilter: "blur(20px)",
        // }}
        className=" lg:w-[95%]  backdrop_image  backdrop-blur-2xl h-fit mx-auto my-auto bg-gray-50 p-5  lg:p-10 flex flex-col gap-5 rounded-sm lg:rounded-2xl"
      >
        <div className="flex items-center ">
        <ArrowLeftCircleIcon
          className="text-gray-700 cursor-pointer"
          onClick={() => {
            setactive(null);
          }}
        />
        <h1
          style={{ backgroundColor: "rgba(255, 255, 255, 0.267)" }}
          className="font-semibold text-2xl text-center  backdrop-blur-xl w-fit mx-auto text-black p-5 rounded-md "
        >
          Pre Links Generator
        </h1>
      </div>
        <div className="Linksinput flex gap-4 items-center">
          <label htmlFor="linkNumber" className="text-2xl">
            Number of Links:
          </label>
          <select
            style={{ backgroundColor: "rgba(255, 255, 255, 0.205)" }}
            name="linkNumber"
            id="linkNumber"
            className="p-2 border-2 border-blue-400 outline-blue-400 w-[100px] rounded-lg"
            onChange={(e) => setNoofLinks(parseInt(e.target.value))}
            value={noofLinks}
          >
            {[...Array(500).keys()].map((i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
          <div>
            <button className="bg-blue-500 active:bg-blue-500 w-fit px-4 ml-5 py-2 rounded-lg text-xl hover:bg-transparent border-blue-500 border-2 hover:text-black text-white" 
            onClick={() => {
              setSelectedType('card')
              setCompany('')
              setLinks({
                custom: [],
                custom_company: [],
                card: [],
                keychain: [],
                button: [],
                display: [],
              })
              setNoofLinks(1)
            }}
            >
              Clear
            </button>
          </div>
        </div>
        <div className="flex gap-10 lg:gap-2 flex-wrap 2xl:flex-nowrap justify-center">
          {Object.entries(links).map(([key, linkList]) => (
            <div
              key={key}
              style={{ backgroundColor: "rgba(255, 255, 255, 0.205)"}}
              className="font-bold backdrop-blur-3xl flex flex-col w-full lg:w-[40%] 2xl:w-[20%] gap-5 rounded-xl bg-gray-100 p-2 border text-lg max-h-[30vh]"
            >
              {key === "custom_company" && company !== null ? (
                <button
                style={{
                  backgroundColor: "#4B70F5",
                  height: "70px",
                }}
                className="bg-blue-500 transition-all text-xl py-5 text-white rounded-lg"
                onClick={() => generateLink(key)}
                disabled={!company}
              >
                Generate {key.replace(/([a-z])([A-Z])/g, "$1 $2")} Link
              </button>
              ) : (
                <button
                  style={{
                    backgroundColor: "#4B70F5",
                    height: "70px",
                  }}
                  className="bg-blue-500 transition-all text-xl py-5 text-white rounded-lg"
                  onClick={() => generateLink(key)}
                >
                  Generate {key.replace(/([a-z])([A-Z])/g, "$1 $2")} Link
                </button>
              )}
              
              {
                key === "custom_company" ? (
                  <div className="flex flex-row items-center gap-2">
                    <label htmlFor="inputSelect">Enter Company Name:</label>
                    <input 
                        id="inputSelect"
                        className="p-3"
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                    />
                    <label htmlFor="typeSelect" className="mr-2">Choose the type of device:</label>
                    <select
                        id="typeSelect"
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        className="p-2 border rounded"
                    >
                        <option value="card">Card</option>
                        <option value="keychain">Keychain</option>
                        <option value="button">Button</option>
                        <option value="display">Display</option>
                        <option value="custom">Custom</option>
                    </select>
                </div>

                ): null
              }
              
              <ul className="font-normal text-blue-600 h-[80%] overflow-y-auto overflow-x-hidden flex flex-col gap-2 text-[90%]">
                {linkList.map((link, index) => (
                  <li key={index} className="flex justify-between px-5">
                    <a href={link}>{link}</a>
                    <FontAwesomeIcon
                      onClick={() => deleteLink(link)}
                      icon={faSquareMinus}
                      size="1x"
                      color="crimson" 
                      style={{cursor: 'pointer'}}
                      /> 
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <button className="bg-blue-500 active:bg-blue-500 w-fit px-4 py-2 rounded-lg text-xl hover:bg-transparent border-blue-500 border-2 hover:text-black text-white" onClick={downloadCSV}>
          Save
        </button>
      </div>
      ) : active === 1 ? (
        <ImportManager setactive={setactive} />
      ) : active === 2 ? (
        <GivePro setactive={setactive} />
      ) : 
      (
        ""
      )}
    </div>

  );
}

export default LinkGenerator;